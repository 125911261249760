export interface SystemState {
  initialized: boolean;
  authenticated: boolean;
  expired: boolean;
  fetching: boolean;
  error: null | Error;
  user?: Physician;
}

export interface Physician {
  id: string;
  firstname: string;
  lastname: string;
  language: string;
  email: string;
  phone: string;
  masterPhysician: boolean;
}

export const INIT_SYSTEM = "INIT_SYSTEM";
export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_RESPONSE = "LOGIN_RESPONSE";
export const LOGOUT_RESPONSE = "LOGOUT_RESPONSE";

interface InitSystemAction {
  type: typeof INIT_SYSTEM;
  payload: {
    authenticated: boolean;
    expired: boolean;
    user?: Physician;
  };
}

interface LoginRequestAction {
  type: typeof LOGIN_REQUEST;
}

interface LoginResponseAction {
  type: typeof LOGIN_RESPONSE;
  payload: {
    error?: any;
    user?: Physician;
  };
}

interface LogoutResponseAction {
  type: typeof LOGOUT_RESPONSE;
}

export type SystemActionTypes =
  | InitSystemAction
  | LoginRequestAction
  | LoginResponseAction
  | LogoutResponseAction;
